import React from "react"
import PropTypes from "prop-types"
import Fade from "react-reveal/Fade"
import _ from "lodash"
import moment from "moment"

import { isEven, isOdd } from "../../utils/numbers"
import ContentPreview, {
  ContentPreviewLayouts,
} from "../preview/ContentPreview"
import indexColumns from "../../utils/indexColumns"
import Arrow from "../../svg/Arrow"

/**
 * News list container displaying results
 */

class NewsList extends React.Component {
  constructor(props) {
    super(props)

    // first on top
    // this.NewsFeatured = this.getNews(0, 1)[0]
    this.sortNews()

    // all news excluding featured
    // this.NewsIndex = this.getNews(0, this.propsNewsLength())
    this.NewsIndex = this.sortNews()

    // column settings
    this.maxVisible = 8 // max visible before adding "show all button"
    this.columnMax = 8 // maximum number of column before reverting count to 1

    // create class instance for column number, delay, etc
    this.indexColumns = new indexColumns(0, this.columnMax)

    this.state = {
      hiddenClass: `is-hide`,
      loadMoreClass: `is-visible`,
    }

    this.showAll = this.showAll.bind(this)
  }

  propsNews() {
    return this.props.allNews && this.props.allNews.edges !== undefined
      ? this.props.allNews.edges
      : this.props.allNews
  }

  propsNewsLength() {
    return this.propsNews() ? this.propsNews().length : 0
  }

  sortNews() {
    // flatten all work
    let allNews = this.propsNews()

    // sort by date first
    allNews = _.sortBy(allNews, news => {
      // flatten node
      let _news = typeof news.node !== `undefined` ? news.node : news

      // convert date to unix timestamp
      let newsDate = moment(_news.publishDate).unix()

      // sort by unix timestamp, reversed
      return newsDate * -1
    })

    // now sort with sticky elements first
    allNews = _.sortBy(allNews, function(news) {
      // flatten node
      let _news = typeof news.node !== `undefined` ? news.node : news
      // console.log(_news)
      return _news.sticky === true ? 0 : 1
    })

    //
    // NOTE: leave for testing sorting in console
    //
    // allNews.map((news, index) => {
    //   let _news = typeof news.node !== `undefined` ? news.node : news
    //   console.log(index, _news.title, _news.publishDate, _news.sticky);
    // })

    return allNews
  }

  getNews(slice = 0, length) {
    return _.slice(this.propsNews(), slice, length)
  }

  visibity(index) {
    return index <= this.maxVisible ? `is-visible` : this.state.hiddenClass
  }

  layout() {
    let className = []

    if (this.indexColumns.columnNumber === 1) {
      className.push(`col-12 col-md-6`)
      className.push(ContentPreviewLayouts.compact_fill)
    } else if (this.indexColumns.columnNumber === 2) {
      className.push(`col-12 col-md-6`)
      className.push(ContentPreviewLayouts.compact)
    } else if (this.indexColumns.columnNumber > 2) {
      className.push(`col-12 col-md-6`)
      className.push(ContentPreviewLayouts.compact)
    }

    // and left/right gutters on some column numbers
    if (isEven(this.indexColumns.columnNumber)) {
      className.push(`right-gutter`)
    } else if (isOdd(this.indexColumns.columnNumber)) {
      className.push(`left-gutter`)
    }

    return className.join(` `)
  }

  listNews() {
    if (this.NewsIndex.length > 0) {
      // XXX: fixing hot reloader issue
      this.indexColumns.reset()

      // will hold content return
      let content = []

      // loop each news
      this.NewsIndex.map((news, index) => {
        // set map index non 0 based
        index = index += 1

        this.indexColumns.increment()

        content.push(
          <ContentPreview
            key={index}
            index={index}
            contentData={news}
            contentType="news"
            layout={this.layout()}
            withDate={true}
            visible={this.visibity(index)}
            delay={this.indexColumns.delay}
          />
        )

        // push toggle nav when maximum visible is reached
        if (index === this.maxVisible) {
          content.push(
            <Fade key={new Date()} top distance="15%">
              <nav
                className={`col-12 nav-show-more ${this.state.loadMoreClass}`}
                key={new Date()}
              >
                <a href="#" className="btn__arrow" onClick={this.showAll}>
                  <span>Load more</span>
                  <Arrow direction="bottom" />
                </a>
              </nav>
            </Fade>
          )
        }
      })

      return content
    } else {
      return <h3>Nothing found... :(</h3>
    }
  }

  showAll(e) {
    e.preventDefault()
    this.setState({
      hiddenClass: `is-visible`,
      loadMoreClass: `is-hide`,
    })
  }

  render() {
    return (
      <section className="news__list">
        <header className="single__header">
          <p className="h4 text-center">News</p>
        </header>

        <div className="row">
          {/* <div className="col-12">{this.props.ContentFilters()}</div> */}
          {this.listNews()}
        </div>
      </section>
    )
  }
}

NewsList.propTypes = {
  allNews: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default NewsList
